import _ from 'lodash'

const errors = {
  tabCustomer: {
    isError: false,
    messages: null
  },
  tabProperti: {
    isError: false,
    messages: null
  },
  tabAngsuran: {
    isError: false,
    messages: null
  },
  tabSignature: {
    isError: false,
    messages: null
  }
}

const getDefaultState = () => {
  return {
    // global
    activeTab: 0,
    errors: errors,
    isAnyUnsavedChanges: false,

    // tab customer
    tabCustomer: {},

    // tab properti
    tabProperti: {},

    // tab angsuran
    tabAngsuran: {},

    // tab angsuran
    tabSignature: {}
  }
}

export default {
  namespaced: true,

  state: getDefaultState(),

  mutations: {
    SET_ACTIVE_TAB (state, tabIndex) {
      document.body.scrollTop = 0 // safari
      document.documentElement.scrollTop = 0 // chrome, firefox, ie and opera
      state.activeTab = tabIndex
    },
    SET_ERRORS (state, payload) {
      const errors = payload
      const tabCustomerErrors = _.pickBy(errors, (value, key) => _.startsWith(key, 'tabCustomer'))
      const tabPropertiErrors = _.pickBy(errors, (value, key) => _.startsWith(key, 'tabProperti'))
      const tabAngsuranErrors = _.pickBy(errors, (value, key) => _.startsWith(key, 'tabAngsuran'))
      const tabSignatureErrors = _.pickBy(errors, (value, key) => _.startsWith(key, 'tabSignature'))

      state.errors.tabCustomer.messages = tabCustomerErrors
      state.errors.tabProperti.messages = tabPropertiErrors
      state.errors.tabAngsuran.messages = tabAngsuranErrors
      state.errors.tabSignature.messages = tabSignatureErrors

      state.errors.tabCustomer.isError = !_.isEmpty(tabCustomerErrors)
      state.errors.tabProperti.isError = !_.isEmpty(tabPropertiErrors)
      state.errors.tabAngsuran.isError = !_.isEmpty(tabAngsuranErrors)
      state.errors.tabSignature.isError = !_.isEmpty(tabSignatureErrors)
    },
    CLEAR_ERRORS (state) {
      state.errors = _.cloneDeep(errors)
    },
    SET_ANY_UNSAVED_CHANGES (state, isAnyUnsavedChanges) {
      state.isAnyUnsavedChanges = isAnyUnsavedChanges
    },
    RESET_STATE (state) {
      Object.assign(state, getDefaultState())
    },
    SET_TAB_CUSTOMER (state, payload) {
      state.tabCustomer = payload
    },
    SET_TAB_PROPERTI (state, payload) {
      state.tabProperti = payload
    },
    SET_TAB_ANGSURAN (state, payload) {
      state.tabAngsuran = payload
    },
    SET_TAB_SIGNATURE (state, payload) {
      state.tabSignature = payload
    }
  },

  actions: {}
}
