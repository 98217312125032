<template>
  <div>
    <div class="vx-row mb-3">
      <div class="vx-col w-full">
        <ValidationErrors :errors="errors"/>
      </div>
    </div>
    <div class="vx-row mb-3">
      <div class="vx-col w-full">
        <div class="con-vs-alert con-vs-alert-primary con-icon">
          <div class="vs-alert con-icon">
            <i class="vs-icon notranslate icon-scale icon-alert feather icon-info null"></i>
            Silahkan tambahkan tanda tangan digital.
          </div>
        </div>
      </div>
    </div>

    <div class="vx-row mb-3">
      <div class="vx-col sm:w-3/12 w-full">
        <p class="font-semibold">TTD Customer</p>
        <img v-if="data.signatureDataUrl" alt="Signature" class="w-full bg-white border border-primary border-solid" :src="data.signatureDataUrl"/>
        <vs-button @click="showSignaturePad = true" class="mt-3 px-3" size="small" icon-pack="feather" icon="icon-edit-2">{{ data.signatureDataUrl ? 'Ulangi TTD' : 'Tambah TTD' }}</vs-button>
      </div>
    </div>

    <div class="vx-row float-right mt-6">
      <div class="vx-col w-full flex">
        <vs-button @click="prev" class="mr-3" type="border" icon-pack="feather" icon="icon-arrow-left">Kembali</vs-button>
      </div>
    </div>

    <!--modals-->
    <SignaturePad :isActive.sync="showSignaturePad" @saved="onSignatureSaved"/>

  </div>
</template>

<script>
import ValidationErrors from '@/views/components/validation-errors/ValidationErrors'
import SignaturePad from '@/views/components/signature-pad/SignaturePad'
import _ from 'lodash'

export default {
  name: 'TabSignature',
  props: {
    initData: { default: null, type: Object } // for edit
  },
  components: {
    SignaturePad,
    ValidationErrors
  },
  computed: {
    errors () {
      return this.$store.state.marketing.bookingAdd.errors.tabSignature.messages
    }
  },
  watch: {
    initData (newVal, oldVal) {
      if (!_.isEmpty(newVal) && !_.isEqual(newVal, oldVal)) {
        this.data = _.cloneDeep(newVal)
      }
    },
    data: {
      deep: true,
      handler (value) {
        this.commitDataToStore(value)
      }
    }
  },
  data () {
    return {
      showSignaturePad: false,
      data: {
        signatureBlob: null,
        signatureDataUrl: null
      }
    }
  },
  methods: {
    onSignatureSaved (data) {
      this.data.signatureBlob = data.blob
      this.data.signatureDataUrl = data.dataUrl
    },

    commitDataToStore: _.debounce(function () {
      const payload = this.buildPayload()
      this.$store.commit('marketing/bookingAdd/SET_TAB_SIGNATURE', payload)
    }, 500),

    prev () {
      this.$store.commit('marketing/bookingAdd/SET_ACTIVE_TAB', 2)
    },

    buildPayload () {
      return {
        signature: this.data.signatureBlob
      }
    }
  }
}
</script>
